import React from 'react';
// import { useParams } from 'react-router-dom';

const BookSorting = () => {
    // const sortType = useParams();
    // console.log(sortType);
    return (
        <div></div>
    )
}

export default BookSorting;
